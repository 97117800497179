









































































import Vue from 'vue';
import { DropdownOption } from '@/dropdown-options';
import { WarrantyInfoResponse } from '@/types/api/Warranty';
export default Vue.extend({
  'name': 'CustomerLanding',
  data () {
    return {
      'form': {
        'icOrEmail': '',
        'warrantyType': 2
      },
      'warrantyTypes': [
        {
          'text': 'Warranty Type: Auto',
          'value': 2
        },
        {
          'text': 'Warranty Type: Building',
          'value': 1
        }
      ] as Array<DropdownOption>,
      'errorMessage': ''
    };
  },
  'methods': {
    onSubmit () {
      this.errorMessage = 'Searching for customer...';
      this.axios
        .get('warranty/public/list', {
          'params': {
            'icOrEmail': this.form.icOrEmail
          }
        })
        .then(({ data }) => {
          this.errorMessage = '';
          const response = data as WarrantyInfoResponse;

          if (this.form.warrantyType === 1) {
            if (response.result.buildingWarranties && response.result.buildingWarranties.buildingWarranties.length > 0) {
              this.$router.push({
                'name': 'CustomerWarranty',
                'query': {
                  'icOrEmail': this.form.icOrEmail,
                  'warrantyType': this.form.warrantyType + ''
                }
              });
            } else {
              this.errorMessage = 'Customer has no building product warranty.';
            }
          } else if (this.form.warrantyType === 2) {
            if (response.result.autoWarranties && response.result.autoWarranties.autoWarranties.length > 0) {
              this.$router.push({
                'name': 'CustomerWarranty',
                'query': {
                  'icOrEmail': this.form.icOrEmail,
                  'warrantyType': this.form.warrantyType + ''
                }
              });
            } else {
              this.errorMessage = 'Customer has no auto product warranty.';
            }
          }
        })
        .catch((err) => {
          if (
            err &&
                        err.response &&
                        err.response.data &&
                        err.response.data.message
          ) {
            this.errorMessage = err.response.data.message;
          } else {
            this.errorMessage =
                            'An error occurs. Please try again later.';
          }
        });
    }
  }
});
